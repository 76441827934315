<!-- src/App.vue -->
<template>
  <div id="app">
    <router-view></router-view> <!-- Здесь будут отображаться ваши страницы -->
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
/* Общие стили приложения, если они есть */
#app {
  margin: 0;
  padding: 0;
}
</style>
