<template>
  <div>
    <canvas ref="chartCanvas"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import Papa from 'papaparse';

Chart.register(...registerables); // Register Chart.js components

export default {
  data() {
    return {
      chartInstance: null, // Store chart instance here
      chartData: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Number of Bets' // Show rounded number of bets
            },
            ticks: {
              maxTicksLimit: 10, // Limit the number of labels on the x-axis
              autoSkip: true,
              maxRotation: 0,
              minRotation: 0
            }
          },
          y: {
            title: {
              display: true,
              text: 'Cumulative PNL Norm'
            }
          }
        },
        elements: {
          point: {
            radius: 0 // Disable point display
          }
        },
        plugins: {
          legend: {
            display: false // Hide the legend
          }
        }
      }
    };
  },
  mounted() {
    this.loadCsvData();
  },
  methods: {
    loadCsvData() {
      Papa.parse('/csv/newdf.csv', {
        download: true,
        header: true,
        complete: (results) => {
          const data = results.data.map(row => parseFloat(row.pnl_norm));

          // Filter out invalid data
          const validData = data.filter(value => !isNaN(value));

          // Calculate cumulative profit
          let cumulativeData = [];
          validData.reduce((acc, curr, index) => {
            cumulativeData[index] = acc + curr;
            return cumulativeData[index];
          }, 0);

          // Reduce the number of displayed points (aggregate points intelligently)
          const reducedLabels = [];
          const reducedData = [];
          const step = Math.ceil(validData.length / 50); // Adjust number of points to 50
          
          for (let i = 0; i < cumulativeData.length; i += step) {
            // Round to the nearest 500
            const roundedBets = Math.round(i / 500) * 500;
            reducedLabels.push(roundedBets); // Use the rounded number of bets
            reducedData.push(cumulativeData[i]);
          }

          // Update chart data
          this.chartData = {
            labels: reducedLabels,
            datasets: [{
              label: 'Cumulative PNL Norm',
              data: reducedData,
              borderColor: '#2ecc71', // Set line color to green
              fill: false,
              tension: 0.1 // Add a slight curve to the line
            }]
          };

          // Render the chart manually
          this.renderChartManually();
        }
      });
    },
    renderChartManually() {
      if (this.chartInstance) {
        this.chartInstance.destroy(); // Destroy previous chart instance to avoid memory leaks
      }
      
      const ctx = this.$refs.chartCanvas.getContext('2d'); // Get canvas context
      this.chartInstance = new Chart(ctx, {
        type: 'line',
        data: this.chartData,
        options: this.chartOptions
      });
    }
  }
};
</script>

<style scoped>
canvas {
  width: 100% !important;
  height: 500px !important; /* Set a larger height to make the chart more readable */
}
</style>
