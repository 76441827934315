<!-- src/components/Contact.vue -->
<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <h1>Contact Us</h1>
    <!-- Добавьте форму или контактную информацию -->
    <p>Email: rassvet1927@gmail.com</p>
    <p>Telegram: @pesokot</p>
  </div>
</template>

<script>
export default {
  name: 'ContactPage' // Изменили имя компонента на многословное
};
</script>

<style scoped>
/* Стили для страницы контактов */
</style>
