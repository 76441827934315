<!-- src/components/Home.vue -->
<template>
  <div>
    <header>
      <h1>Beat Pinnacle</h1>
      <p>We beat Pinnacle with 1%+ ROI, 7000+ bets per month, ~$4000 median maximum bet.</p>
      <p>We know how to beat any bookies with the best solutions.</p>
      <p>We can significantly increase the volume of bets and the quality of your value bets.</p>
      <p>All this will lead to an increase in the LTV (Lifetime Value) of client accounts. The more the client earns, the more you will earn.</p>
      <p>We are best in soccer.</p>
    </header>

    <section>
      <h2>Our Results</h2>
      <CsvChart></CsvChart> <!-- Используем компонент с учётом регистра -->
    </section>

    <section>
      <h2>Who Would Be Interested</h2>
      <ul>
        <li>Value Betting Services: Get an edge over competitors.</li>
        <li>Syndicates: Gain insights for working with bookmakers.</li>
      </ul>
    </section>

    <section>
      <h2>Coverage</h2>
      <p>
        Live: Soccer, Basketball, Baseball<br>
        Pre-match: Soccer, Tennis, Basketball, Baseball
      </p>
    </section>

    <section>
      <h2>FAQ</h2>
      <div class="faq">
        <h3>Who are we?</h3>
        <p>We are a small team of mathematicians and developers engaged in betting for 10 years, 7 of which in value betting. We understand what we're doing.</p>

        <h3>Can we further improve our product?</h3>
        <p>Of course, we have a detailed roadmap with various event options.</p>

        <h3>What are your results based on?</h3>
        <p>Big data and qualitatively collected necessary data.</p>

        <h3>How are we better?</h3>
        <p>We offer unique insights and superior performance compared to competitors.</p>
      </div>
    </section>

    <section class="cta-buttons">
      <button @click="buyNow">Buy Now</button>
      <button @click="getInTouch">Contact Us</button>
    </section>
  </div>
</template>

<script>
import CsvChart from './CsvChart.vue';

export default {
  name: 'HomePage', // Changed from 'Home' to 'HomePage'
  components: {
    CsvChart
  },
  methods: {
    buyNow() {
      this.$router.push('/buy');
    },
    getInTouch() {
      this.$router.push('/contact');
    }
  }
};
</script>


<style>
#app {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #2c3e50;
  margin: 0;
}

header {
  background-color: #3498db;
  color: white;
  padding: 60px 20px;
}

header h1 {
  margin: 0;
  font-size: 3em;
}

header p {
  font-size: 1.2em;
  margin: 10px 0;
}

section {
  padding: 40px 20px;
}

h2 {
  color: #3498db;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  font-size: 1.2em;
  margin: 10px 0;
}

.faq h3 {
  margin-top: 20px;
  color: #2c3e50;
}

.faq p {
  font-size: 1em;
  color: #555;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.cta-buttons button {
  margin: 10px;
  padding: 15px 25px;
  font-size: 1.2em;
  cursor: pointer;
  border: none;
  color: white;
  background-color: #3498db;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.cta-buttons button:hover {
  background-color: #2980b9;
}
</style>
